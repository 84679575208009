import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {REACT_APP_API_SERVICE_URL} from '../../../config';

const loungeLoginApiSlice = createApi({
    reducerPath: 'loungeLoginApi',
    baseQuery: fetchBaseQuery({
        baseUrl: REACT_APP_API_SERVICE_URL,
    }),
    endpoints: (builder) => ({
        getParticipantInformation: builder.query({
            query: ({participantId, hiveId, loginToken}) => ({
                url: `logins/${participantId}/profile`,
                params: {
                    hiveid: hiveId,
                },
                headers: {Authorization: `Bearer ${loginToken}`},
                method: 'GET',
            }),
        }),
        getLoungeMeetings: builder.query({
            query: ({hiveId, loginId, loginToken, loungeSceneId}) => ({
                url: 'meetings',
                params: {
                    hiveid: hiveId,
                    loginid: loginId,
                    type: 'Lounge',
                    pageId: loungeSceneId,
                },
                headers: {
                    Authorization: `Bearer ${loginToken}`,
                    "virtualhive-tenant": hiveId,
                },
                method: 'GET',
            }),
        }),
    }),
});

export const {
    useLazyGetParticipantInformationQuery,
    useLazyGetLoungeMeetingsQuery,
} = loungeLoginApiSlice;

export default loungeLoginApiSlice;
